
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.side-nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  padding-bottom: 30px;
  overflow-y: auto;
  color: white;
  background-color: $gk-dark-blue;
  box-shadow: 0 3px 6px #00000026;

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 22px;
    .close-button {
      padding: 5px;
      margin-right: 20px;
    }

    .logo {
      width: 100%;
      max-width: 200px;
      &-wrapper {
        margin: 0 auto;
      }
    }
    // This makes the logo centered with even with close button
    &::after {
      width: 30px;
      margin-left: 20px;
      content: '';
      @include bp-lg-laptop {
        display: none;
      }
    }
  }

  .main-nav {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: auto;
    padding: 0;
    padding-bottom: 27px;
    margin: 0;
    margin-top: 0;
    list-style-type: none;

    .nav-item {
      display: block;
      flex: 1 1 20%;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: $gk-white;
    }

    .route {
      display: block;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 10px 10px 10px 27px;
      color: inherit;
      text-align: center;
      text-align: left;
      text-decoration: none;
      @include bp-md-tablet {
        height: 50px;
      }

      &:hover {
        background-color: rgba($gk-grey, 0.5);
      }
      &.router-link-active {
        background-color: $gk-grey;
      }
    }

    .icon-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }

    .route-text {
      display: block;
      margin-top: 4px;

      @include bp-md-tablet {
        display: inline-block;
        margin-top: 0;
        vertical-align: middle;
      }
    }
  }

  .logout-button {
    --g-button-icon-gap: 10px;

    padding-right: 27px;
    padding-left: 27px;
    margin-top: auto;
    color: white;
    background: none;

    ::v-deep {
      .icon-left {
        margin-right: 10px;
      }
    }
  }
}
