
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.cms-layout {
  .navigation {
    ::v-deep {
      > .content {
        overflow: visible;
      }
      .content-body {
        height: 100%;
      }
    }
  }
  &.desktopView {
    .navigation {
      width: 192px;
      ::v-deep {
        overflow: visible;
        background-color: transparent;
        > .content {
          max-width: 192px;
        }
      }
    }
    .main-content {
      margin-left: 192px;
    }
  }
  &.mobileView {
    .navigation {
      > ::v-deep .content {
        max-width: initial;
      }
    }
  }
}
